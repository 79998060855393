import React from 'react';
import {Card, List, ListItem, ListItemAvatar,ListItemText} from "@material-ui/core";
import history from "../../history";

export const EventTable=({events,link})=>{
    return (
        <div>
            <List>
                {events.map(index=>{
                    const {data,id}=index;
                    const {title,logo,venue,date}=data;
                    return (
                        <Card style={{margin:5}} key={id}>
                            <ListItem button onClick={()=>history.push(link+`/${id}`)}>
                                <ListItemAvatar>
                                    <img alt={'logo'} height={50}  src={logo.url} style={{marginRight:10}}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={title}
                                    secondary={
                                        <div>
                                            <p>Date: <b>{date} </b>    Venue: <b>{venue}</b></p>
                                        </div>}
                                />
                            </ListItem>
                        </Card>
                    )
                })}
            </List>
        </div>
    )
};


