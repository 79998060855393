import React from "react";
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import headerStyle from "../../assets/jss/material-kit-react/components/headerStyle";
import {Link} from "react-router-dom";

const logo = require('../../assets/img/Main_Logo.png');

const CenterHeader=(props)=>{
        const {classes}=props;
        const appBarClasses=classNames(classes.appBar,classes.absolute,classes.fixed, classes.white)
        return (
            <AppBar
                className={appBarClasses}
                style={{alignItems:'center', justifyContent:'center'}}
            >
                <Link to={'/'}><img src={logo} height={45}/></Link>
            </AppBar>
        )
}
export default withStyles(headerStyle)(CenterHeader);