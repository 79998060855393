import React from "react";
import {Router,Route}from 'react-router-dom';
import history from "./history";
//components
import './assets/css/material-dashboard-react.css';
import Home from "./Screens/Home";
import LiveEvent from "./Screens/LiveEvent";
import Terms from "./Screens/Terms";
import Privacy from "./Screens/Privacy";
import ConsentCookie from "./components/ConsentCookie";
const App =()=>{
    return (
        <div>
            <ConsentCookie/>
            <Router history={history}>
                <Route exact path={'/'} component={Home}/>
                <Route path='/live-event/:id' exact component={LiveEvent}/>
                <Route path={'/terms'} exact component={Terms}/>
                <Route path={'/privacy'} exact component={Privacy}/>
            </Router>
        </div>

    )
}

//todo - add cookie consent
export default App;