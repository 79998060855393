import React from "react";
import {CircularProgress} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";


export const SimpleLoadingSpin=(props)=>{
    const {show}=props;
    return (
        <GridContainer justify={'center'} alignItems={'center'} display={show?'block':'none'}>
            <CircularProgress/>
        </GridContainer>
    )
};