import {LOAD_LIVE_EVENT, REMOVE_LIVE_EVENT} from "../types";

const INITIAL_STATE = {
    data:null
};

export default (state=INITIAL_STATE, action)=>{
    switch(action.type){
        case LOAD_LIVE_EVENT:
            return {data:action.payload};
        case REMOVE_LIVE_EVENT:
            return {data:null};
        default:
            return {...state};
    }
}