import React from 'react';
import {connect}from 'react-redux';
import Hidden from "@material-ui/core/es/Hidden/Hidden";
import {setLiveEventData} from "../Store/Actions/index";
import Loading from "../components/Loading";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Button from "../components/CustomButtons/Button";
import {Link} from "react-router-dom";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import FullScreen from "react-full-screen";
import LiveTable from "../components/Table/LiveTable";
import {roundsDataFunction} from "../Functions/EventFunctions";
import {RemoveRedEye,ChevronLeft,ChevronRight, Fullscreen} from "@material-ui/icons";
import Table from "../components/Table/Table";
import StartListModal from "../components/Modals/StartListModal";
import CenterHeader from "../components/Header/CenterHeader";
import WhiteFooter from "../components/Footer/WhiteFooter";

class LiveEvent extends React.Component{

    state={
      loading:true,
      eventLoading:true,
      eventLoadingErr:null,
      itemNo:0,
      fullscreen:false,
      Interval:null,
      startListModal:false,
      startListNumbers:[],
        startListModalHeading:''
    };

    componentDidMount=()=>{
        const eventId = this.props.eventId;
        this.props.setLiveEventData(eventId,res=>{
            res.status===1?
                this.setState({
                    loading:false
                }):
                this.setState({
                    loading:false,
                    eventLoadingErr:res.msg
                })

        })
    };

    renderEvent=()=>{
        const eventData=this.props.eventData;

        if(eventData!== null){
            const sections = eventData.sections;
            if(sections !== null && sections !== undefined){
                const roundsData=roundsDataFunction(sections,this.state.itemNo,false);
             //  console.log(Object.keys(roundsData));
                const roundsKeys = Object.keys(roundsData);
                if(roundsKeys.length>0){
                    const tableData = roundsKeys.sort((a,b)=>a-b).reduce((a,v)=>{
                        const {title,roundType,roundNo,status, startList}=roundsData[v];
                        //console.log(roundsData[v]);
                        a.push([
                            v,
                            [title,startList.sort((a,b)=>a-b)],
                            roundType,
                            roundNo,
                            status,

                        ]);
                        return a;
                    },[]);

                    return (
                        <LiveTable
                            tableHeaderColor={'info'}
                            tableHead={['Item No','Title','Round Type','Round No','Status']}
                            tableData={tableData}
                        />
                    )
                }

            }else{
                return <div>No Sections have been added yet!</div>
            }


        }
    };
    renderInfo=()=>{
        const eventData=this.props.eventData;
        if(eventData !== null){
            const {title,scrutineer,chairPerson,venue,date} = eventData.info;

            const {fullscreen,itemNo}=this.state;
            return (
                <Card style={{width:'95%'}}>
                    <CardHeader color={'info'}>
                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
                            <p>Title:<b>{title}</b></p>
                            <p>Date:{date}</p>
                            <p>Venue:{venue}</p>
                            <p>Chairperson:{chairPerson}</p>
                            <p>Scrutineer:{scrutineer}</p>
                        </div>


                    </CardHeader>
                    <CardBody>
                        {this.renderEvent()}
                        <GridContainer justify='center' alignItems='center' >
                            {fullscreen?
                                <p>Items {itemNo}-{itemNo+10}</p>:
                                <GridItem container direction={'row'} justify={'center'} alignItem={'center'}>
                                    <Button onClick={()=>this.handleBackward()} justIcon color='info' size='sm' round ><ChevronLeft/></Button>
                                    <p> Items {itemNo}-{itemNo+10} </p>
                                    <Button onClick={()=>this.handleForward()} justIcon color='info' size='sm' round><ChevronRight/></Button>
                                </GridItem>
                            }
                        </GridContainer>
                    </CardBody>
                </Card>
            )

        }
    };

    changeItemNo=(Total)=>{
        const currentSet=parseInt(this.state.itemNo);
        const newSet = currentSet+10;
        if(newSet<=Total){
            // change state to new set
            this.setState({itemNo:newSet});
        }else{
            // change to first set
            this.setState({itemNo:0});
        }
    };
    Timer=(mode)=>{
        if(mode==='start'){
            const eventData=this.props.eventData;
            const TotalRounds = Object.keys(roundsDataFunction(eventData.sections,0,true)).length;
            const intervalId =setInterval(()=>this.changeItemNo(TotalRounds),5000);
            this.setState({Interval:intervalId})
        }else{
            clearInterval(this.state.Interval);
            //this.setState({itemNo:0})
        }
    };

    handleForward=()=>{
        const eventData=this.props.eventData;
        const TotalRounds = Object.keys(roundsDataFunction(eventData.sections,0,true)).length;
        this.changeItemNo(TotalRounds);
    };
    handleBackward=()=>{

        const currentSet=parseInt(this.state.itemNo);
        const newSet = currentSet-10;
        if(newSet<0){
            this.setState({itemNo:0})
        }else{
            this.setState({itemNo:newSet})
        }

    };


    handleFullScreen=()=>{
        this.Timer('start');
        this.setState({fullscreen: true})
    };

    handleFullScreenChange=(isFull)=>{
      if(!isFull){
          this.Timer('clear');
          //console.log('clear timer')
      }
        this.setState({fullscreen:isFull})
    };

    renderMobile=()=>{
        const eventData=this.props.eventData;
        if(eventData!== null){
            const {title,date,venue}=eventData.info;
            const sections = eventData.sections;

            if(sections !== null && sections !== undefined){

                const roundsData = roundsDataFunction(sections,this.state.itemNo,true);
                const tableData = Object.keys(roundsData).sort((a,b)=>a-b).reduce((a,v)=>{

                    const {title,roundType,roundNo,status,startList}=roundsData[v];

                    a.push([
                        v,
                        title+'-'+roundType,
                        roundNo,
                        status,
                        <Button onClick={()=>this.setState({startListModal:true,startListNumbers:startList, startListModalHeading: title+'-'+roundType})} size='sm' justIcon color='info' round>
                            <RemoveRedEye/>
                        </Button>
                    ]);
                    return a;
                },[]);

                return (
                    <div>
                        <Card>
                            <CardHeader color={'info'}>
                                <h4>{title}</h4>
                                <p>Date: {date}</p>
                                <p>Venue:{venue}</p>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHeaderColor='info'
                                    tableHead={['Item','Title','Round No','Status','Action']}
                                    tableData={tableData}
                                />
                            </CardBody>
                        </Card>
                    </div>
                )




            }
            else{
                return (
                    <div>
                        <Card>
                            <CardHeader color={'info'}>
                                <h4>{title}</h4>
                                <p>Date: {date}</p>
                                <p>Venue:{venue}</p>
                            </CardHeader>
                            <CardBody>
                                <h4>No Sections Added yet</h4>
                            </CardBody>
                        </Card>
                    </div>
                )
            }

        }else{
            return (<div>No Event Data!</div>)
        }
    };

    render(){
        const { classes, ...rest } = this.props;
        const {loading, eventLoadingErr, fullscreen, startListModal,startListNumbers, startListModalHeading}=this.state;
        return (
            <div>
                <Loading
                    show={loading}
                />
                <StartListModal
                    isVisible={startListModal}
                    numbers={startListNumbers}
                    heading={startListModalHeading}
                    onClose={()=>this.setState({startListModal:false,startListModalHeading: '', startListNumbers: []})}
                />
                <CenterHeader/>
                <div style={{marginTop:100}}>
                    <Hidden only={['xs','sm']}>
                        <GridItem container justify={'center'} alignitem={'center'}>
                            <Button style={{alignSelf:'center'}} color='info' size='sm' round onClick={()=>this.handleFullScreen()}><Fullscreen/>FullScreen</Button>
                        </GridItem>
                        <FullScreen style={{alignSelf:'center',justifyContent:'center'}} enabled={fullscreen} onChange={(isFull)=>this.handleFullScreenChange(isFull)}>
                            <GridContainer>
                                <GridItem style={{justifyContent:'center'}} container direction='row' justify='center' alignItems='center'>
                                    {eventLoadingErr!==null?
                                        (
                                            <div>
                                                <h3>{eventLoadingErr}</h3>
                                                <Link to={'/live'}>
                                                    <Button size='sm' color='info' round>View Live Events</Button>
                                                </Link>
                                            </div>
                                        )
                                        :this.renderInfo()
                                    }

                                </GridItem>
                            </GridContainer>
                        </FullScreen>
                    </Hidden>
                    <Hidden only={['md','lg','xlg']}>
                        {eventLoadingErr!== null?
                            (<div>
                                <h3>{eventLoadingErr}</h3>
                                <Link to={'/'}>
                                    <Button size='sm' color='info' round>View Live Events</Button>
                                </Link>
                            </div>):this.renderMobile()
                        }
                    </Hidden>
                </div>
                <WhiteFooter/>
            </div>
        )
    }
}
const mapStateToProps=(state,ownProps)=>{
    return {
        eventId:ownProps.match.params.id,
        eventData:state.liveData.data
    }

};


export default connect(mapStateToProps, {setLiveEventData})(LiveEvent);
