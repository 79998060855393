import {combineReducers} from "redux";

//custom reducers
import liveEvents from './events';
import liveData from './viewEventReducer';
import Disclaimers from './DisclaimerReducer';
const index={
    liveEvents,
    liveData,
    Disclaimers
};

export default combineReducers(index);