import {Link} from "react-router-dom";
import React from "react";

export const TitleLink=({name})=>{
    return (
        <h4 style={FooterStyles.titleLink}>{name}</h4>
    )
}
const colorCheck=(color)=>{
    switch(color){
        case'blue':
            return FooterStyles.siteLinkBlue;
        case 'gold':
            return FooterStyles.siteLinkGold;
        default:
            return FooterStyles.siteLinkWhite
    }
}

export const SiteLink=({name,link,type,color})=>{

    return type==='link'?
        <a style={colorCheck(color)} href={link} rel="noopener noreferrer" target={'_blank'}>{name}</a>:
        <Link style={colorCheck(color)} to={link}>{name}</Link>
}

export const FooterStyles={
    imageGrid:{
        maxHeight:250
    },
    linksContainer:{
        paddingLeft:10
    },
    whiteContainer:{
        backgroundColor:"white",
        width:'100%',
    },
    blueContainer:{
        backgroundColor:"#143a5c",
        width:'100%',
    },
    goldContainer:{
        backgroundColor:'#ab8b60',
        width:'100%'
    },
    image:{
        height:'35%',
        width:'auto',
        margin:0,
        padding:0
    },
    titleLink:{
        lineHeight:'0.5 em',
        color:'#ab8b60'
    },
    siteLinkWhite:{
        color:'white',
        paddingLeft:5,
        paddingRight:5
    },
    siteLinkBlue:{
        color:'#143a5c',
        paddingLeft:5,
        paddingRight:5
    },
    siteLinkGold:{
        color:'#ab8b60',
        paddingLeft:5,
        paddingRight:5
    }
}


