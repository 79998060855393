import React from 'react';
import {connect}from 'react-redux';
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import {getLiveEvents} from "../Store/Actions";
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "../assets/jss/material-kit-react/views/landingPage";
import Parallax from "../components/Parallax/Parallax";
import {SimpleLoadingSpin} from "../components/Loading/SimpleLoading";
import {EventTable}from '../components/Table/EventsTable';
import WhiteFooter from "../components/Footer/WhiteFooter";
import CenterHeader from "../components/Header/CenterHeader";


class Home extends React.Component{

    state={
        eventsErr:'',
        dataLoading:true,
        loading:false
    };

    componentDidMount=()=>{
        this.props.getLiveEvents((res)=>{
            res.status ===1?
                this.setState({
                    dataLoading:false,
                }):
                this.setState({
                    dataLoading:false,
                    eventsErr:res.msg
                })
        })
    };

    renderEvents=()=>{
        const events = this.props.events;
        if(events !== null && events.length>0){
                 return (
                     <EventTable
                         events={events}
                         link={'/live-event'}
                     />
                 )

        }else{
            if(this.props.loaded){
                return (
                    <div>
                        <h5>There are no live events currently!</h5>
                    </div>
                )
            }
        }
    };

    render(){

        const { classes, ...rest } = this.props;
        return (
            <div>
                <CenterHeader
                    classes={classes}
                />
                <Parallax small filter image={require('../assets/img/backgrounds/LiveEvents-bg.jpeg')}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} container alignItems={'center'} justify={'center'} direction={'column'}>
                                <h1><b>Live Events</b></h1>
                                <p style={{textAlign:'center',fontSize:18}}>All below events are live events set by the organizers and scrutineers hosted by the Dance Suite System. Dance Suite is not responsible for the accuracy of the information provided by these users. Should you have any queries please contact the event organizers.</p>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classes.section}>
                    <div style={{padding:'3%'}}>
                        <GridContainer>
                            <GridItem xs={12} container alignItems={'center'} justify={'center'} >
                                {this.state.dataLoading?
                                    <SimpleLoadingSpin show={this.state.loading}/>:null
                                }
                            </GridItem>
                            <GridItem xs={12} >
                                {this.renderEvents()}
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
                <WhiteFooter/>
            </div>
        )
    }
}
const mapStateToProps=state=>{
    return {
        events :state.liveEvents.events,
        loaded:state.liveEvents.loaded
    }
};

export default connect(mapStateToProps,{getLiveEvents})(withStyles(landingPageStyle)(Home));