import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import tableStyle from "../../assets/jss/material-dashboard-react/components/tableStyle.jsx";



function LiveTable({...props}){
    const { classes, tableHead, tableData, tableHeaderColor } = props;
    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                    <TableRow>
                        {tableHead.map((prop, key) => {
                            return (
                                <TableCell
                                    className={classes.tableCell + " " + classes.tableHeadCell}
                                    key={key}
                                >
                                    {prop}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((prop,key)=>{
                        return (
                            <TableRow key={key}>
                                {prop.map((prop,key)=>{
                                    return (
                                        <TableCell className={classes.tableCell} key={key}>
                                            {key===1?
                                                <div>
                                                    <b>{prop[0]}</b><br/>
                                                    {prop[1].join(', ')}
                                                </div>:prop}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    )

}

export default withStyles(tableStyle)(LiveTable);