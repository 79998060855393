import {LIVE_EVENTS} from "../types";
const INITIAL_STATE={
    events:null,
    loaded:false
};

export default (state=INITIAL_STATE,action)=>{
    if(action.type===LIVE_EVENTS){
        return {
            events:action.payload,
            loaded:true
        };
    }else{
        return {events:null, loaded:false}
    }

}