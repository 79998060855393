import {database, dbFire} from "../../Firebase";
import axios from 'axios';
import {LIVE_EVENTS, LOAD_LIVE_EVENT, LOAD_PRIVACY, LOAD_TERMS} from "../types";

export const getLiveEvents=(callback)=>async (dispatch)=>{
    const response = await axios.get('https://us-central1-dance-suite.cloudfunctions.net/liveEvents');
    if(response.data.status===1){
        const events = response.data.data;
        const eventDates = Object.keys(events).reduce((a,v)=>{
            const {date}=events[v].info;
            if(!a.includes(date)){
                a.push(date);
            }
            return a
        },[]);
        const sortedEvents = Object.keys(eventDates).sort().reverse().reduce((a,v,i)=>{
            Object.keys(events).map(id=>{
                const {date}=events[id].info;
                if(date===eventDates[i]){
                    a.push(
                        {id:id,data:events[id].info}
                    )
                }
            });
            return a;
        },[]);
        dispatch({type:LIVE_EVENTS,payload:sortedEvents});
        callback({status:1})
    }else{
        callback({status:2,msg:response.data.msg})
    }
};

export const setLiveEventData=(eventId, callback)=>async (dispatch)=>{

    const ref = database.ref(`events/${eventId}`);
    ref.on('value',snap=>{
        const eventData =snap.val();
        if(eventData!==null){
            const {status,sections,info,panels}=eventData;
            if(status ==='live'){
                const {title,venue,date,scrutineer,chairPerson,logo, organizer, organization}=info;


                dispatch({type:LOAD_LIVE_EVENT,payload: {
                        info:{
                            logo,
                            title,
                            venue,
                            date,
                            scrutineer: scrutineer.name,
                            chairPerson,
                            organizer:organizer.name,
                            organization
                        },
                        sections,
                        //panels
                    }});

                callback({status:1})
            }else{
                callback({status:2,msg:'Event no longer live!'})
            }
        }else{
            callback({status:2,msg:'Incorrect Screens ID!'})
        }
    })
};

export const GetTerms =(callback)=>dispatch=>{
  const ref = dbFire.collection('disclaimers').doc('terms');
  ref.get()
      .then(doc=>{
        if(doc.exists){
            const {content}=doc.data();
            dispatch({type:LOAD_TERMS,payload:content});
            callback({status:1});

        }else{
            callback({status:2,msg:'Unable to get terms'})
        }
      })
      .catch(err=>err?callback({status:2,msg:err.message}):null)
};
export const GetPrivacy=(callback)=>dispatch=>{
    const ref = dbFire.collection('disclaimers').doc('privacy');
    ref.get()
        .then(doc=>{
            if(doc.exists){
                const {content}=doc.data();
                dispatch({type:LOAD_PRIVACY,payload:content});
                callback({status:1});

            }else{
                callback({status:2,msg:'Unable to get terms'})
            }
        })
        .catch(err=>err?callback({status:2,msg:err.message}):null)
};