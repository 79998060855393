export const roundsDataFunction =(sections,listNum,mobile)=>{
    return Object.keys(sections).reduce((a,v)=>{
        const {rounds,title,type}=sections[v];
        if(rounds !== undefined){
            Object.keys(rounds).map(round=>{
                const {itemNo,startList,roundNo,roundType,status,dances}=rounds[round];
                if(mobile){
                    a[itemNo]={
                        itemNo,
                        startList:startList!== undefined?startList:[],
                        title:type==='multiDance'?title+'-'+dances[0]:title,
                        roundNo,
                        roundType,
                        status
                    }
                }else{
                    if(parseInt(itemNo)<=listNum+10 && parseInt(itemNo)>listNum){
                        a[itemNo]={
                            itemNo,
                            startList:startList!== undefined?startList:[],
                            title:type==='multiDance'?title+'-'+dances[0]:title,
                            roundNo,
                            roundType,
                            status
                        }
                    }
                }

            })
        }
        return a;
    },{});
};