import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import Transition from "../../Functions/Transistion";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";

class StartListModal extends React.Component{

    renderNumbers=()=>{
        const numbers = this.props.numbers;
        if(numbers !== null && numbers.length >0){
            return (
                <GridContainer>
                    {numbers.map(number=>{
                        return (
                            <GridItem xs={3} key={number}>
                                <h4>{number}</h4>
                            </GridItem>
                        )
                    })}
                </GridContainer>
            )
        }else{
            return (<div>No Dancers have been added to this round yet!</div>)
        }
    };

    render(){
        const {classes, onClose,isVisible, heading}=this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={onClose}>
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <h4 className={classes.modalTitle}>{heading}</h4>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        {this.renderNumbers()}
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            round
                            type='button'
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withStyles(modalStyle)(StartListModal)


